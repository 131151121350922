export const types = {
    
    SET_LOG_IN_CIT_CLIENTE : 'SET_LOG_IN_CIT_CLIENTE',
    SET_LOG_OUT_CIT_CLIENTE : 'SET_LOG_OUT_CIT_CLIENTE',
    SET_PASO_0: 'SET_PASO_0',
    SET_PASO_1: 'SET_PASO_1',
    SET_PASO_2: 'SET_PASO_2',
    SET_PASO_3: 'SET_PASO_3',
    CLEAN_INPUTS: 'CLEAN_INPUTS',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    TOKEN_EXPIRED_CLEAN: 'TOKEN_EXPIRED_CLEAN',
}